import React, { Component } from 'react';
import {
  Header,
  HeaderName,
  HeaderGlobalBar,
} from 'carbon-components-react/lib/components/UIShell';
import {
  Button,
} from 'carbon-components-react';

class CustomHeader extends Component {
  render() {
    return (
      <Header aria-label="IBM Platform Name">
        <HeaderName href="#" prefix="">
          Oskar
        </HeaderName>
        <HeaderGlobalBar>
          <Button href="https://budgeting.oskarapp.com/">Login</Button>
        </HeaderGlobalBar>
      </Header>
    );
  }
}

export default CustomHeader;
