import React from 'react';
import './App.css';
import {
  Content,
} from 'carbon-components-react/lib/components/UIShell';
import Hero from './components/Hero';
import CustomHeader from './components/Header';
import FAQ from './components/FAQ';

function App() {
  return (
    <div className="App">
      <CustomHeader />
      <Content id="main">
        <Hero />
        <FAQ />
      </Content>
    </div>
  );
}

export default App;
