import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
} from 'carbon-components-react';

const faqs = [
  {
    question: "What is Oskar?",
    answer: "A budgeting app for departments. At least that is what it wants to be, eventually. It is still under development, however.",
  },
  {
    question: "What can Oskar do?",
    answer: "So far, unfortunately, not much. Oskar is still in development.",
  },
  {
    question: "How can I start using Oskar?",
    answer: "Unfortunately, you cannot start yet. Oskar is still under development.",
  },
  {
    question: "When is Oskar going to be released?",
    answer: "We are not quite sure yet. Hopefully, it is going to be released one day.",
  },
  {
    question: "How can I stay updated about the development of Oskar?",
    answer: "You can't... yet. But we are working on it.",
  },
]

class FAQ extends Component {
  render() {
    return (
      <div className="faq">
        <h2>Frequently Asked Questions</h2>
        <Accordion>
          {faqs.map(faq => (
            <AccordionItem title={<p>{faq.question}</p>}>
              <p>{faq.answer}</p>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default FAQ;
