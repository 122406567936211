import React, { Component } from 'react';
import {
  Button,
} from 'carbon-components-react';

class Hero extends Component {
  render() {
    return (
      <div className="hero">
        <div className="hero-text">
          <h1>This is an intervention.</h1>
          <p>Budgeting doesn't have to be hard. This isn't rocket science.</p>
          <Button className="demo-button" href="https://budgeting.oskarapp.com/">Login to Oskar</Button>
        </div>
      </div>
    );
  }
}

export default Hero;
